import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <div className="header-offset">
    <div className="privacy-policy-container">
      <h1>Privacy Policy</h1>
      <p><strong>Last Updated:</strong> May 11, 2022</p>

      <h2>Privacy Policy</h2>
      <p>
        Sweetspot Brands LLC, a Rhode Island limited liability company doing business as “Sweetspot” (“us,” “we,” or the “Company”) is committed to respecting the privacy of our in-store, online, delivery, and mobile visitors and users. This Privacy Policy (“Privacy Policy” or “Policy”) applies to the collection and use of information from and about visitors and users (collectively, “Users” or “you”) through our retail locations, our website, our mobile applications and SMS texting, and our product delivery offerings (collectively, the “Services”). By using the Services, you agree to the terms and conditions of this Privacy Policy.
      </p>
      <p>
        We will not use or share your personal information except as described in this Privacy Policy. This Privacy Policy applies to information collected by Sweetspot and does not apply to information collected by any third party sites to which the Services may link or to information provided to, or collected by, third parties through third-party cookies, web beacons, or other third-party technologies on our website or mobile application.
      </p>
      <p>
        Where we rely on your consent to process your personal information, you can withdraw such consent at any time as described in the “Rights to Opt-In / Opt-out” section of this Privacy Policy.
      </p>
      <p>
        If you have questions or concerns regarding this Privacy Policy, you should contact us via e-mail at <a href="mailto:privacy@sweetspotfarms.com">privacy@sweetspotfarms.com</a>, or via postal mail at the address listed in the “Contact Us” section of this Policy.
      </p>

      <h2>Terms of Use</h2>
      <p>
        For further details on using our Services, please refer to our Terms of Use Agreement available on the Sweetspot website at: <a href="https://sweetspotfarms.com/terms-use" target="_blank" rel="noopener noreferrer">https://sweetspotfarms.com/terms-use</a>.
      </p>
      <p>
        By using Company’s Services in person or via the Internet, including Sweetspot’s website, you agree to be bound by the Company’s Terms of Use that are expressly incorporated into this Policy by this reference.
      </p>

      <h2>Changes to Privacy Policy</h2>
      <p>
        This Policy is published on the Company’s website at <a href="https://sweetspotfarms.com/privacy-policy" target="_blank" rel="noopener noreferrer">https://sweetspotfarms.com/privacy-policy</a>. Changes to this Privacy Policy will become effective on the date they are posted on the Services. We reserve the right to change this Privacy Policy at any time and from time to time in our sole and absolute discretion without the duty to notify you. Changes, modifications, additions, or deletions will be effective immediately on their posting to the Services.
      </p>
      <p>
        Your continued use of the Services after we post any such modifications will constitute your acknowledgment of the modified Privacy Policy and your agreement to abide and be bound by the modified Privacy Policy. We will also revise the “last updated” date found at the beginning of this Privacy Policy when we post changes to it.
      </p>

      <h2>Disclaimers</h2>
      <p>
        This Privacy Policy does not extend to anything that is inherent in the operation of the Internet, and therefore beyond Sweetspot’s control, and is not to be applied in any manner contrary to applicable law or governmental regulation. This Privacy Policy only applies to information collected through the Services.
      </p>

      <h2>No Use of the Services by Persons Under 21 Permitted</h2>
      <p>
        The Company intends only persons who are 21 years or older to use the Services. Any use of the Services by persons under the age of 21 is strictly prohibited.
      </p>

      <h2>Cannabis Use in Society</h2>
      <p>
        Not everyone accepts cannabis use as permissible. By interacting with Sweetspot Services, providing your information to Sweetspot, or using Sweetspot products, you acknowledge potential risks and accept them as your own responsibility.
      </p>

      {/* More sections can follow, formatted similarly. */}
      
      <h2>Contact Us</h2>
      <p>
        If you have any questions, please contact us:
        <ul>
          <li>Email: <a href="mailto:privacy@sweetspotfarms.com">privacy@sweetspotfarms.com</a></li>
          <li>Address: Sweetspot Brands LLC, 100 Pioneer Ave, Warwick, RI 02888</li>
          <li>Phone: 401-432-7200</li>
        </ul>
      </p>
    </div>
    </div>
  );
};

export default PrivacyPolicy;
