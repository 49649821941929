import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../Franchise.css';

import RoundedButton from '../../RoundedButton.tsx';

export default function FranchiseBanner() {
  const navigate = useNavigate();

  const handleInfoButton = () => {
    navigate('/franchising/info-request');
  };

  return (
    <>
      <div className="BannerBackground" />
      <div className="Banner">
        <div className="contentWrapper">
          <div className="content">
            <h1>
              <span>WELCOME TO</span>
              <span>SWEETSPOT</span>
              <span>FRANCHISING</span>
            </h1>
            <div className="infoButtonContainer">
              <RoundedButton href="https://sweetspot-web-legacy-ee0da6c33a23.herokuapp.com/franchising/info-request">
                REQUEST INFORMATION
              </RoundedButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
