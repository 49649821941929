import React from 'react';
import './HeroBanner.css';
import heroVideo from './landing-banner-video-03.mp4';

function HeroBanner() {
  return (
    <div className="hero-banner">
      <div className="hero-content">
        <div className="video-wrapper">
          <video
            autoPlay
            loop
            muted
            playsInline
            className="hero-video"
          >
            <source src={heroVideo} type="video/mp4" />
          </video>
        </div>
      </div>
      
      <div className="hero-title-container">
        <div className="hero-title-text">
          <span className="desktop-title">
            Take Control of Your<br />
            Cannabis Experience
          </span>
          <span className="mobile-title">
            Take Control of<br />
            Your Cannabis<br />
            Experience
          </span>
        </div>
        <a href="/store" className="hero-button">
          <div className="hero-button-circle"></div>
          <span className="hero-button-text">
            FIND A SWEETSPOT STORE
          </span>
        </a>
      </div>
    </div>
  );
}

export default HeroBanner;