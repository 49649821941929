import React, { useState, useEffect } from 'react';
import './OurStores.css';
import essexJunctionImage from './Homepage_Store_Essex Junction.png';
import exeterImage from './Homepage_Store_Exeter.png';
import stamfordImage from './Homepage_Store_Stamford.png';
import westHartfordImage from './Homepage_Store_West Hartford.png';
import RoundedButton from './RoundedButton.tsx';

const OurStores: React.FC = () => {
    const locations = [
        { image: essexJunctionImage, caption: 'Essex Junction, VT' },
        { image: exeterImage, caption: 'Exeter, RI' },
        { image: stamfordImage, caption: 'Stamford, CT' },
        { image: westHartfordImage, caption: 'West Hartford, CT' }
    ];
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [previousImageIndex, setPreviousImageIndex] = useState(0);
    const [slideDirection, setSlideDirection] = useState<'left' | 'right' | ''>('');
    const [userHasInteracted, setUserHasInteracted] = useState(false);
    
    const nextImage = () => {
        if (slideDirection) return; // Prevent transitions during animation
        setPreviousImageIndex(currentImageIndex);
        setSlideDirection('left'); // slides to the left
        setCurrentImageIndex((prevIndex) => 
            prevIndex === locations.length - 1 ? 0 : prevIndex + 1
        );
    };

    useEffect(() => {
        const interval = setInterval(() => {
            if (!slideDirection) {
                nextImage();
            }
        }, 2000);

        return () => clearInterval(interval);
    }, [slideDirection]);

    const handleAnimationEnd = () => {
        setSlideDirection('');
    };

    return (
        <div className="our-stores">
            <div className="image-carousel">
                <div className="carousel-container">
                    <div className="carousel-image-container">
                        <div className="image-wrapper">
                            {slideDirection && (
                                <img 
                                    src={locations[previousImageIndex].image}
                                    alt={`Store in ${locations[previousImageIndex].caption}`}
                                    className={`carousel-image exit-${slideDirection}`}
                                />
                            )}
                            <img 
                                src={locations[currentImageIndex].image} 
                                alt={`Store in ${locations[currentImageIndex].caption}`} 
                                className={`carousel-image ${slideDirection ? `enter-${slideDirection}` : ''}`}
                                onAnimationEnd={handleAnimationEnd}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="title-section">
                <h2 className="our-stores-title">OUR STORES</h2>
                <div className="title-divider"></div>
            </div>
            <div className="store-divider"></div> {/* Add horizontal line */}
            {/* Desktop description */}
            <div className="store-description desktop-description">
                We make shopping for cannabis<br /> worry-free with expert recommendations,
                <br />quality products, and daily discounts.
            </div>
            
            
            {/* Mobile description */}
            <div className="store-description mobile-description">
                We make shopping for <br />cannabis
                worry-free with<br /> expert
                recommendations, <br />quality products 
                and <br />daily discounts.
            </div>
            
            <div className="button-container">
                <RoundedButton href="/store">FIND A STORE NEAR YOU</RoundedButton>
            </div>
        </div>
    );
};

export default OurStores;
