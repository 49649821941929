import React from 'react';
import { OverlayView } from '@react-google-maps/api';

const CustomInfoWindow = ({
  position,
  onCloseClick,
  children,
}) => {
  // Use OVERLAY_MOUSE_TARGET to ensure the overlay can receive mouse events
  const mapPaneName = OverlayView.OVERLAY_MOUSE_TARGET;

  return (
    <OverlayView position={position} mapPaneName={mapPaneName}>
      <div style={{ 
        background: '#FFFEF1',
        border: '1px solid #ccc',
        padding: '10px',
        borderRadius: '6px',
        boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
        position: 'relative',
        width: '200px',
        color: '#00443B',
        fontFamily: 'Work Sans',

      }}>
        <button
          style={{
            cursor: 'pointer',
            position: 'absolute',
            top: '5px',
            right: '8px',
            background: 'none',
            border: 'none',
            fontWeight: 'bold',
            fontSize: '14px',
            fontFamily: 'Work Sans',
            color: '#00443B'
          }}
          onClick={onCloseClick}
        >
          ×
        </button>
        {children}
      </div>
    </OverlayView>
  );
};

export default CustomInfoWindow;
