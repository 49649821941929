import React from 'react';
import './ProductInfo.css';
import oneProductImage from './one-product-01.png';

const ProductInfo = () => {
    return (
        <div className="product-info">
            <h2 className="product-title">PRE-ROLL TINS</h2>
            <div className="image-container">
                <div className="description-container top">
                    <div className="description-text">
                        Recyclable + <br/>
                        lightweight<br/> 
                        tin sleeve
                    </div>
                    <div className="pointing-stroke"></div>
                </div>
                <div className="description-container bottom">
                    <div className="description-text">
                        100% plastic-free
                    </div>
                    <div className="pointing-stroke"></div>
                </div>
                <div className="description-container bottom-right">
                    <div className="pointing-stroke right"></div>
                    <div className="description-text right">
                        Post-consumer<br/>
                        recycled + recyclable<br/>
                        kraft paper sleeve <br/>
                        and inserts
                        
                    </div>
                </div>
                <img src={oneProductImage} alt="Product Information" className="product-image" />
            </div>
            <div className="mobile-descriptions">
                <div className="mobile-description-text right-column">
                    100% <br></br>plastic-free
                </div>
                <div className="mobile-description-text right-column">
                    Recyclable + lightweight tin sleeve
                </div>
                <div className="mobile-description-text left-column">
                    Post-consumer <br></br> recycled + <br></br>recyclable <br></br>kraft paper sleeve <br></br> and inserts
                </div>
            </div>
        </div>
    );
};

export default ProductInfo;
