import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function StoreMenu({ menuId }: { menuId: string }) {
  const navigate = useNavigate();
  
  useEffect(() => {
    // Navigate to the menu page route
    navigate(`/menu/${menuId}`);
  }, [menuId, navigate]);

  // Return null since we're navigating away
  return null;
}