import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';

import './App.css';
import Header from './Header.tsx';
import HeroBanner from './HeroBanner.tsx';
import ScrollingMarquee from './ScrollingMarquee.tsx';
import ThreeColumnSection from './ThreeColumnSection.tsx';
import OurStores from './OurStores.tsx';
import Products from './Products.tsx';
import Footer from './Footer.tsx';
import PrivacyPolicy from './PrivacyPolicy.tsx';
import HIPAA from './HIPAA.tsx';
import TermsOfUse from './TermsOfUse.tsx';
import LegalDisclaimers from './LegalDisclaimers.tsx';
import RefundPolicy from './RefundPolicy.tsx';
import Accessibility from './Accessibility.tsx';
import Learn from './Learn.tsx';
import About from './About.tsx';
import Stores from './Stores.tsx';
import Franchising from './franchise/Franchise.tsx';
import SustainableFarming from './SustainabilityBanner.tsx';
import CtCannabisInfo from './CannabisSafetyInformation.tsx';
import Sustainability from './Sustainability.tsx';
import AgeGate from './AgeGate.tsx';
import { StackAdapt } from './StackAdapt.tsx';
import StoreMenu from './StoreMenu.tsx';
import MenuPage from './MenuPage.tsx';
import { Helmet } from 'react-helmet';

// Wrapper component to handle location-based footer visibility
function AppContent() {
  const [menu, setMenu] = useState<string | null>(null);
  const [storeId, setStoreId] = useState<string | null>(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isAgeVerified, setIsAgeVerified] = useState(false);
  const location = useLocation();

  // Handle menu selection
  const handleMenuSelection = (menuId: string) => {
    setMenu(menuId);
  };

  const handleLocationSelection = (storeId: string) => {
    console.log('Location selection in App:', storeId);
    setStoreId(storeId);
  };

  useEffect(() => {
    const verified = localStorage.getItem('ageVerified');
    if (verified === 'true') {
      setIsAgeVerified(true);
    }
  }, []);

  if (!isAgeVerified) {
    return <AgeGate onVerified={() => setIsAgeVerified(true)} />;
  }

  // Check if current route is menu page
  const isMenuPage = location.pathname.startsWith('/menu/');

  return (
    <>
      <StackAdapt />
      <div className="App" style={{ display: 'flex', flexDirection: 'column' }}>
        <Header 
          onMenuToggle={setIsMobileMenuOpen} 
          onMenuSelect={handleLocationSelection}
        />
        
        <div className="main-content">
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <Helmet>
                    <meta charSet="utf-8" />
                    <title>Sweetspot Cannabis Dispensary: Home</title>
                    <meta name="description" content="Shop medical and recreational marijuana, pre-rolls, edibles, and more across CT, MD, NJ, RI, and VT." />
                    <meta name="keywords" content="cannabis dispensary, marijuana dispensary, medical cannabis, recreational marijuana, cannabis products, Sweetspot Cannabis" />
                    <meta property="og:title" content="Sweetspot Cannabis Dispensary: Home" />
                    <meta property="og:description" content="Your premier destination for premium cannabis products. Medical and recreational dispensaries across multiple states." />
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content="https://sweetspotfarms.com" />
                    <meta name="robots" content="index, follow" />
                    <link rel="canonical" href="https://sweetspotfarms.com/" />
                  </Helmet>
                  <HeroBanner />
                  <div className="content-below-hero">
                    <ScrollingMarquee />
                    <ThreeColumnSection />
                    <OurStores />
                    <Products />
                    <SustainableFarming />
                  </div>
                </>
              }
            />
            
            {/* New dynamic store routes */}
            <Route path="/store" element={<Stores onMenuSelect={handleMenuSelection} />} />
            <Route path="/store/:state" element={<Stores onMenuSelect={handleMenuSelection} />} />
            <Route path="/store/:state/:storeName" element={<Stores onMenuSelect={handleMenuSelection} />} />
            
            {/* Store redirects for legacy URLS */}
            <Route path="/store/ct001" element={<Navigate to="/store/connecticut/west-hartford" replace />} />
            <Route path="/store/ct002" element={<Navigate to="/store/connecticut/stamford" replace />} />
            <Route path="/store/md001" element={<Navigate to="/store/maryland/olney" replace />} />
            <Route path="/store/nj001" element={<Navigate to="/store/new-jersey/voorhees" replace />} />
            <Route path="/store/nj002" element={<Navigate to="/store/new-jersey/maplewood" replace />} />
            <Route path="/store/ri001" element={<Navigate to="/store/rhode-island/exeter" replace />} />
            <Route path="/store/vt001" element={<Navigate to="/store/vermont/essex-junction" replace />} />

            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/hipaa" element={<HIPAA />} />
            <Route path="/terms-of-use" element={<TermsOfUse />} />
            <Route path="/legal-disclaimers" element={<LegalDisclaimers />} />
            <Route path="/refund-policy" element={<RefundPolicy />} />
            <Route path="/CtCannabisInfo" element={<CtCannabisInfo />} />
            <Route path="/accessibility" element={<Accessibility />} />
            <Route path="/franchising" element={<Franchising />} />
            <Route path="/learn" element={<Learn />} />
            <Route path="/about" element={<About />} />
            <Route path="/franchising/*" element={<Franchising />} />
            <Route path="/sustainability" element={<Sustainability />} />
            <Route path="/menu/:menuId" element={<MenuPage />} />
            
            {/* Add this catch-all route at the end for bad or unsupported links */}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
          {menu && <StoreMenu menuId={menu} />}
          {storeId && <StoreMenu menuId={storeId} />}
        </div>

        {!isMenuPage && <Footer />}
      </div>
    </>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;