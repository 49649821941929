import React from 'react';

export default function Industry() : any {
  return (
    <section className="Industry">
      <div className="contentWrapper">
        <div className="content">
          <div className="itemsWrapper">
            <div className="item">
              <p className="title">
                38
              </p>
              <p className="description">
                Cannabis is medically legal in 38 states and recreationally legal in 24.  Those numbers continue to grow each year.
              </p>
            </div>
            <div className="item">
              <p className="title">
                $39.9B
              </p>
              <p className="description">
                The U.S. legal cannabis industry is currently $39.9B dollars and is projected to be $72B by 2030.
              </p>
            </div>
            <div className="item">
              <p className="title">
                3.43M
              </p>
              <p className="description">
                There were 3.43 million recreational cannabis users in the United States in 2020.  By 2025, this number is set to double to 6.1 million.
              </p>
            </div>
            <div className="item">
              <p className="title">
                50%
              </p>
              <p className="description">
                Nearly half of all U.S. adults (50.5%) have tried cannabis sometime in their lifetime.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="imageContainer">
        <div className="image" />
        <div className="captionWrapper">
          <div className="caption">
            <p className="title">
              <span>Essential Business</span>
            </p>
            <p className="text">
             2024 - A recommendation from the DEA to reschedule cannabis from a schedule I to a schedule III drug; a historical moment.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
