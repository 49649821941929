import React from 'react';
import { useNavigate } from 'react-router-dom';
import './franchise/Franchise.css';
import LogoIconPaleGreen from './logo-icon-pale-green.png'; 

//import LogoIconPaleGreen from '../assets/images/logo-icon-pale-green.png';
import AnimatedButton from './AnimatedButton.tsx';

const Blurb = (props: any) => {
  const {heading, text, larger, franchiseInfoButton} = props;

  const navigate = useNavigate();

  const handleInfoButton = () => {
    navigate('/franchising/info-request');
  };

  const contentClass = `content${larger ? ' larger' : ''}`;

  return (
    <div className="Blurb">
      <div className="contentWrapper">
        <div className={contentClass}>
        <img src={LogoIconPaleGreen} alt="Sweetspot icon" />
          { heading && (
            <span className="heading">
              {heading}
            </span>
          )}
          { text && (
            <p>
              {text}
            </p>
          )}
          { franchiseInfoButton && (
            <div className="buttonContainer">
              <AnimatedButton
                label="Request Information"
                handler={handleInfoButton}
                className="requestInfo"
              />
            </div>  
          )}
        </div>
      </div>
    </div>
  );
};

export default Blurb;