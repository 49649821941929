import React from 'react';
import './SustainabilityRow.css';

interface SustainabilityRowProps {
    image: string;
    imageAlt: string;
    title: string;
    subtitleDesktop: string; // Separate subtitle for desktop
    subtitleMobile: string; // Separate subtitle for mobile
    className?: string; // Optional class name for styling different rows
}

const SustainabilityRow: React.FC<SustainabilityRowProps> = ({ 
    image, 
    imageAlt, 
    title, 
    subtitleDesktop, 
    subtitleMobile, 
    className = '' // Default to empty string if not provided
}) => {
    return (
        <div className={`sustainability-row ${className}`}>
            <div className="sustainability-row-divider"></div>
            <div className="sustainability-row-content">
                <div className="sustainability-row-image">
                    <img src={image} alt={imageAlt} />
                </div>
                <div className="sustainability-row-text">
                    <div className="sustainability-row-text-divider"></div>
                    <h2 className="sustainability-row-title">
                        {title}
                    </h2>
                    <p 
                        className="sustainability-row-subtitle desktop"
                        dangerouslySetInnerHTML={{ __html: subtitleDesktop }}
                    />
                    <p 
                        className="sustainability-row-subtitle mobile"
                        dangerouslySetInnerHTML={{ __html: subtitleMobile }}
                    />
                </div>
            </div>
        </div>
    );
};

export default SustainabilityRow;
