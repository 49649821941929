import React from 'react';
import './CannabisSafetyInformation.css';

export default function CtCannabisInfo() {
  return (
    <div className="header-offset">
    <div className="ct-cannabis-info-container">
      <h1>CT Cannabis Consumption Information</h1>

      <section>
        <h2>Limitations on Possession and Use</h2>
        <p>
          <strong>Medical Cannabis Patients:</strong> May purchase up to 5.0oz per 30-day cycle unless otherwise specified by their certifying physician. There are no transaction limits for medical patients up to their certified allotment. Cannabis should be stored in the original packaging for identification with the medical label attached. Cannabis should be kept away and out of reach of children and pets.
        </p>
        <p>
          <strong>Adult-Use Consumers, 21+:</strong>
          <ul>
            <li>May purchase ½ oz (14 grams) per transaction unless otherwise noted by the state of CT.</li>
            <li>May possess up to 1.5oz of cannabis on their person. Cannabis should be kept in the original packaging with labeling.</li>
            <li>May possess up to 5.0oz of cannabis in a locked container at home or in a locked glove box or trunk. Must be kept away from persons under 21 years of age. Cannabis should be kept in the original packaging with labeling and out of reach of children and pets.</li>
          </ul>
        </p>
      </section>

      <section>
        <h2>Forms of Use</h2>
        <h3>Inhalation</h3>
        <p>
          Inhalation involves heating cannabis material and inhaling the smoke or vapor produced. Effects typically occur within 5-15 minutes and may last a few hours.
        </p>
        <h4>1. Smoking</h4>
        <p>Methods include:</p>
        <ul>
          <li><strong>Pipes:</strong> Pack cannabis material in the open end, combust using a lighter, and inhale.</li>
          <li><strong>Pre-rolls/Joints:</strong> Cannabis rolled in papers, lit at one end, and inhaled through the other.</li>
        </ul>
        <h4>2. Vaping</h4>
        <p>Methods include:</p>
        <ul>
          <li><strong>Vaporizers:</strong> Heat flower or concentrate below combustion. Various types available.</li>
          <li><strong>Vape Batteries:</strong> Used with pre-filled cartridges or refillable oil syringes.</li>
          <li><strong>Specialty Pods:</strong> Designed for specific battery units like Cliq Pods.</li>
        </ul>
        <h3>Sublingual</h3>
        <p>
          Products such as sprays, tinctures, and drops are absorbed under the tongue or inside the cheek. Effects may vary but can occur within 30 minutes to 1 hour.
        </p>
        <h3>Oral Ingestion</h3>
        <p>
          Edibles, oral solutions, and other ingestibles take 1-2 hours for effects and may last 6-8 hours. Start low and go slow.
        </p>
        <h3>Topical</h3>
        <p>
          Creams, lotions, and balms can be applied to areas of discomfort without systemic effects.
        </p>
      </section>

      <section>
        <h2>Safe Cannabis Consumption</h2>
        <ul>
          <li>Do not mix cannabis with other substances.</li>
          <li>Do not consume cannabis and drive.</li>
          <li>Consult your physician before using cannabis products, especially if pregnant, nursing, or taking medications.</li>
        </ul>
      </section>

      <section>
        <h2>What if You’ve Taken Too High of a Dose?</h2>
        <p>
          While fatal overdose is not known, consuming too much cannabis can cause discomfort. Symptoms include extreme confusion, anxiety, paranoia, panic, increased heart rate, delusions, severe nausea, and vomiting. If needed, call the American Association of Poison Control Centers at 1-800-222-1222.
        </p>
      </section>
      <section>
      <h2>Cannabis Overuse and Dependence</h2>
  <p>
    Psychological dependence is possible with the overuse of any substance. A personal inventory should be taken if marijuana becomes a focal point in your life, and consultation with your physician is recommended. Frequent or heavy use of cannabis can lead to increased tolerance of cannabis, resulting in the need for higher doses or higher potency.
  </p>

  <h2>Signs and Symptoms of Substance Abuse</h2>
  <p>
    According to the American Addiction Center, if a person is abusing any drug, there are some general signs to look for. These include:
  </p>
  <ul>
    <li>Difficulties at school, disinterest in school-related activities, and declining grades</li>
    <li>Poor work performance, being chronically late to work, appearing tired and disinterested in work duties, and receiving poor performance reviews</li>
    <li>Changes in physical appearance, such as wearing inappropriate or dirty clothing and a lack of interest in grooming</li>
    <li>Altered behavior, such as an increased desire for privacy</li>
    <li>Drastic changes in relationships</li>
    <li>A noticeable lack of energy when performing daily activities</li>
    <li>Spending more money than usual or requesting to borrow money</li>
    <li>Issues with financial management, such as not paying bills on time</li>
    <li>Changes in appetite, such as a decreased appetite and associated weight loss</li>
    <li>Bloodshot eyes, poor skin tone, and appearing tired or run down</li>
    <li>Defensiveness when asked about substance use</li>
  </ul>
  </section>
      <section>
        <h2>Resources for Assistance</h2>
        <ul>
          <li><a href="https://americanaddictioncenters.org">AmericanAddictionCenters.org</a> | For 24/7 help call 1-888-987-6323</li>
          <li><a href="https://drugfreect.org">DrugFreeCt.org</a> | For 24/7 help call: 1-800-563-4086</li>
          <li><a href="https://samhsa.gov">SAMHSA.gov</a> | For 24/7 help call: 1-800-662-HELP (4357)</li>
        </ul>
      </section>

      <section>
        <h2>Contact Information</h2>
        <p>
          <strong>Address:</strong> 345 Prospect Ave, West Hartford, CT
        </p>
        <p>
          <strong>Phone:</strong> (860) 838-8330
        </p>
        <p>
          <strong>Email:</strong> <a href="mailto:westhartford@sweetspotfarms.com">westhartford@sweetspotfarms.com</a>
        </p>
      </section>
    </div>
    </div>
  );
}
