import React from 'react';
import './Accessibility.css';

const Accessibility = () => {
  return (
    <div className="header-offset">
    <div className="accessibility-container">
      <h1>Accessibility Statement</h1>
      <p>
        Sweetspot is committed to providing a website that is usable by all people, regardless of technology
        or ability. We aim to meet or exceed all applicable accessibility standards, including the
        requirements of the Web Content Accessibility Guidelines 2.0 Level AA (WCAG 2.0 AA). We
        are in the process of retaining a web accessibility consulting company to conduct an accessibility
        audit of our websites and provide us with specific recommendations that will assist us in ensuring
        that our web presence is in conformance with the WCAG 2.0 AA.
      </p>
      <p>
        Please be aware that our efforts are ongoing, and we are working with our current website provider
        to implement relevant improvements to meet WCAG 2.0 AA guidelines over time. If you
        experience any difficulty in using the processes found within this website or have specific
        questions or concerns about the accessibility of this site, please feel free to call us at <a href="tel:401-432-7200">401-432-7200</a>
        or to email us at <a href="mailto:accessibility@sweetspotfarms.com">accessibility@sweetspotfarms.com</a>. If you do encounter an accessibility issue,
        please be sure to specify the web page and we will make all reasonable efforts to make that page
        accessible or otherwise provide the information or facilitate the transaction you seek through an
        alternate communication method that is accessible for you consistent with applicable law.
      </p>
    </div>
    </div>
  );
};

export default Accessibility;
