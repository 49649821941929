import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './Header.css';
import logoGreen from './sweetspot-logo-green.png';

interface HeaderProps {
  onMenuToggle?: (isOpen: boolean) => void;
  onMenuSelect: (storeId: string) => void;
}

const storeItems = [
  {
      id: 1,
      storeId: '5796',
      name: 'West Hartford, CT - MED',
  },
  {
      id: 2,
      storeId: '5797',
      name: 'West Hartford, CT - REC',
  },
  {
      id: 3,
      storeId: '857',
      name: 'Olney, MD - MED',
  },
  {
      id: 4,
      storeId: '5440',
      name: 'Olney, MD - REC',
  },
  {
      id: 5,
      storeId: '4855',
      name: 'Voorhees, NJ - MED',
  },
  {
      id: 6,
      storeId: '5377',
      name: 'Voorhees, NJ - REC',
  },
  {
    id: 7,
    storeId: '6246',
    name: 'Maplewood, NJ - REC',
},
  {
      id: 8,
      storeId: '4545',
      name: 'Exeter, RI - MED',
  },
  {
      id: 9,
      storeId: '4996',
      name: 'Exeter, RI - REC',
  },
  {
      id: 10,
      storeId: '6145',
      name: 'Essex Junction, VT - REC',
  },
  {
      id: 11,
      storeId: '6158',
      name: 'Stamford, CT - MED',
  },
  {
      id: 12,
      storeId: '6159',
      name: 'Stamford, CT - REC',
  }
];

const Header: React.FC<HeaderProps> = ({ onMenuToggle, onMenuSelect }) => {
  const location = useLocation();
  const isHome = location.pathname === '/';
  const isSustainability = location.pathname === '/sustainability'; // Add this line
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(!isHome); // Initialize based on route
  const [isLocationMenuOpen, setIsLocationMenuOpen] = useState(false);
  const [selectedStore, setSelectedStore] = useState<string | null>(null);

  useEffect(() => {
    // If not on home page, always set scrolled to true
    if (!isHome) {
      setIsScrolled(true);
      return;
    }

    // Only add scroll listener on home page
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isHome]); // Add isHome as dependency

  const toggleMenu = () => {
    const newState = !isMobileMenuOpen;
    setIsMobileMenuOpen(newState);
    onMenuToggle?.(newState);
  };

  const handleMouseEnter = () => {
    setIsLocationMenuOpen(true);
  };

  const handleMouseLeave = () => {
    setIsLocationMenuOpen(false);
  };

  const handleStoreClick = (storeId: string) => {
    console.log('Store clicked in Header:', storeId);
    onMenuSelect(storeId); // Call the prop function directly
    setSelectedStore(storeId);
    setIsLocationMenuOpen(false); // Close the dropdown after selection
  };

  return (
    <header className={`App-header ${isHome ? 'home' : ''} ${isSustainability ? 'sustainability-page' : ''} ${isScrolled ? 'scrolled' : ''}`}>
      <div 
        className={`hamburger-button ${isMobileMenuOpen ? 'open' : ''}`}
        onClick={toggleMenu}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
      
      <div className={`mobile-menu ${isMobileMenuOpen ? 'open' : ''}`}>
        <a href="/store">STORES</a>
        <a href="/sustainability">SUSTAINABILITY</a>
        <a href="https://sweetspot-web-legacy-ee0da6c33a23.herokuapp.com/franchising/" className="franchise-link">FRANCHISE</a>
      </div>

      <nav className="nav-links">
        <a href="/store">STORES</a>
        <a href="/sustainability">SUSTAINABILITY</a>
        <a href="/franchising" className="franchise-link">FRANCHISE</a>
      </nav>
      <div className="logo-container">
        <a href="/">
          <img src={logoGreen} alt="Logo" className="logo-scaled" />
        </a>
      </div>
      <div className="location-menu-container"
           onMouseEnter={handleMouseEnter}
           onMouseLeave={handleMouseLeave}>
        <a href="#" 
           className={`shop-by-location ${isLocationMenuOpen ? 'active' : ''}`}>
          SHOP BY LOCATION
        </a>
        <div className={`location-dropdown ${isLocationMenuOpen ? 'open' : ''}`}>
          {storeItems.map((store, index) => (
            <div key={store.id}>
              <div className="location-item" onClick={(e) => {
          e.stopPropagation();  // Prevent event bubbling
          handleStoreClick(store.storeId);
              }}>
          <p>{store.name}</p>
              </div>
              {index !== storeItems.length - 1 && <hr className="location-divider" />}
            </div>
          ))}
        </div>
      </div>
      <hr className="header-line" />
    </header>
  );
};

export default Header;