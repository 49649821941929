import React from 'react';
import './ScrollingMarquee.css';

const ScrollingMarquee: React.FC = () => {
    // Create multiple instances of text+circle for smooth infinite scroll
    const content = (
        <>
            <span>FIND YOUR SWEETSPOT IN 3 EASY WAYS</span>
            <div className="circle"></div>
        </>
    );

    return (
        <div className="scrolling-marquee">
            <div className="marquee-content">
                {/* Repeat content multiple times for continuous scroll effect */}
                {content}
                {content}
                {content}
                {content}
                {content}
                {content}
            </div>
        </div>
    );
};

export default ScrollingMarquee;
