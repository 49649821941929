import React from 'react';
import './SustainableBanner.css';
import plantVideo from './plant-video-v3.mp4';

export default function SustainableFarming() {
  return (
    <div className="sustainable-farming-container">
      <video
        autoPlay
        loop
        muted
        playsInline
        className="sustainable-video"
      >
        <source src={plantVideo} type="video/mp4" />
      </video>
      <div className="sustainable-farming-content">
        <h1 className="sustainable-title">SUSTAINABILITY</h1>
        <div className="sustainable-title-divider"></div>
        <div className="desktop-line">
          Our sustainable farming practices reduce<br /> our environmental impact,
          while<br /> producing the highest quality<br /> cannabis possible.
        </div>
        <div className="mobile-line">
          Our sustainable farming<br /> practices
          reduce our <br />environmental impact,<br />
          while producing the <br />highest quality
          cannabis <br />possible.
        </div>
        <div className="sustainable-button-container">
          <a href="/sustainability" className="rounded-button">LEARN MORE ABOUT OUR GROW</a>
        </div>
      </div>
    </div>
  );
}
