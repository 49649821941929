import React from 'react';

import InvestmentImage1 from '../../investment-and-fees-1.png';

export default function Investment() {
  return (
    <section className="Investment">
      <div className="contentWrapper">
        <div className="content">
          <div className="heading">
            <p>
              Below is a summary of estimated investment and franchise fees.
            </p>
          </div>
          <div className="itemsWrapper">
            <div className="item">
              <span className="label">
                Investment Range:
              </span>
              <span className="info">
               $1,158,530 to $2,398,530
              </span>
            </div>
            <div className="item">
              <span className="label">
                Liquid Capital Requirement:
              </span>
              <span className="info">
                $500,000
              </span>
            </div>
            <div className="item">
              <span className="label">
                Product Sourcing Fee:
              </span>
              <span className="info">
                2% of gross sales (capped at $249,999 per calendar year).
              </span>
            </div>
            <div className="item">
              <span className="label">
                Franchise Fee:
              </span>
              <span className="info">
                $40,000
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="imageContainer">
        <div className="image1">
          <img src={InvestmentImage1} alt="A shopper investigates a product while standing beside a wall of product shelving" />
        </div>
        <div className="image2" />
        <div className="image3" />
      </div>
    </section>
  );
}
