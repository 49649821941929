import React from 'react';
import './TermsOfUse.css';

const TermsOfUse = () => {
  return (
    <div className="header-offset">
    <div className="terms-of-use-container">
      <h1>Terms of Use</h1>
      <p><strong>Last Updated:</strong> May 11, 2022</p>

      <h2>Terms of Use</h2>
      <p>
        This constitutes a binding agreement ("Terms," "Terms of Use," or "Agreement") between you and Sweetspot Brands LLC, a Rhode Island limited liability company, doing business as "Sweetspot" ("Sweetspot," "us," "we," or the "Company").
      </p>
      <p>
        These Terms apply to all services provided by Sweetspot ("Services") where information is collected from or about you, or where data from or about you is used. Services include retail sales and age verification at stores branded or owned by Sweetspot ("Stores"), as well as physical delivery of products to you ("Delivery Service"). Services also include digital services provided through the Internet website located at <a href="https://www.sweetspotfarms.com" target="_blank" rel="noopener noreferrer">www.sweetspotfarms.com</a> (the "Website" or "Site"), applications or software provided online or in conjunction with the Website, and electronic communications sent to you by Sweetspot or its partners (collectively, "Digital Services").
      </p>
      <p>
        By using Sweetspot Services, you signify that you have read, understood, and agree to be bound by this Agreement, whether or not you are a registered user of our Services. If you do not agree, do not use Company’s Services, including its Website.
      </p>

      <h2>Privacy Policy</h2>
      <p>
        The Company respects your privacy and permits you to control the treatment of your personal information. A complete statement of the Company’s current Privacy Policy can be found on the Website by visiting <a href="https://sweetspotfarms.com/privacy-policy" target="_blank" rel="noopener noreferrer">https://sweetspotfarms.com/privacy-policy</a>.
      </p>
      <p>
        By using Company’s Services, including the Website, you agree to be bound by the Company’s Privacy Policy that is expressly incorporated into this Agreement by this reference.
      </p>

      <h2>Changes to Terms of Use</h2>
      <p>
        The Company may modify these Terms of Use from time to time in its sole and absolute discretion and without the duty to notify you of such modification. If we do this, we will post the amended Terms on this Website page and indicate at the top of the page the date the Agreement was last revised. Your continued use of our Services after any such changes constitutes your acceptance of the new Terms of Use.
      </p>

      <h2>No Use of the Services by Persons Under 21 Permitted</h2>
      <p>
        Our Services are intended for a mature audience. The Company intends only persons who are 21 years or older to use the Services, including the Website. You agree that by using the Services, you represent that you are at least 21 years old and that you are legally able to enter into this Agreement.
      </p>

      <h2>Legal Disclaimers</h2>
      <p>
        By using Company’s Services, including the Website, you agree to be bound by the Company’s Legal Disclaimers published on the Website that are expressly incorporated into this Agreement by this reference. To access the Legal Disclaimers, visit the Website at <a href="https://sweetspotfarms.com/legal-disclaimers" target="_blank" rel="noopener noreferrer">https://sweetspotfarms.com/legal-disclaimers</a>.
      </p>

      <h2>Contact Us</h2>
      <p>
        For questions or comments, please email <a href="mailto:privacy@sweetspotfarms.com">privacy@sweetspotfarms.com</a> or write to:
      </p>
      <ul>
        <li>Sweetspot Brands LLC</li>
        <li>100 Pioneer Ave, Warwick, RI 02888</li>
      </ul>
    </div>
    </div>
  );
};

export default TermsOfUse;
