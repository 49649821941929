import React from 'react';
import './HIPAA.css';

const HIPAA = () => {
  return (
    <div className="header-offset">
    <div className="hipaa-container">
      <h1>Your Information. Your Rights. Our Responsibilities.</h1>
      <p><strong>Effective Date:</strong> May 11, 2022</p>

      <p>
        THIS NOTICE DESCRIBES HOW MEDICAL INFORMATION ABOUT YOU MAY BE USED
        AND DISCLOSED AND HOW YOU CAN GET ACCESS TO THIS INFORMATION. PLEASE
        REVIEW IT CAREFULLY.
      </p>

      <h2>Your Rights</h2>
      <p>You have the right to:</p>
      <ul>
        <li>Get a copy of your paper or electronic medical record (e.g. medical marijuana purchase history)</li>
        <li>Correct your paper or electronic medical record</li>
        <li>Request confidential communication</li>
        <li>Ask us to limit the information we share</li>
        <li>Get a list of those with whom we’ve shared your information</li>
        <li>Get a copy of this privacy notice</li>
        <li>Choose someone to act for you</li>
        <li>File a complaint if you believe your privacy rights have been violated</li>
      </ul>

      <h2>Your Choices</h2>
      <p>You have some choices in the way that we use and share information as we:</p>
      <ul>
        <li>Tell family and friends about your condition or status as a medical marijuana patient</li>
        <li>Provide disaster relief</li>
        <li>Share your information as detailed below</li>
      </ul>

      <h2>Our Uses and Disclosures</h2>
      <p>We may use and share your information as we:</p>
      <ul>
        <li>Treat you</li>
        <li>Run our organization</li>
        <li>Get paid for your medical marijuana</li>
        <li>Help with public health and safety issues</li>
        <li>Do research</li>
        <li>Comply with the law</li>
        <li>Respond to organ and tissue donation requests</li>
        <li>Work with a medical examiner or funeral director</li>
        <li>Address workers’ compensation, law enforcement, and other government requests</li>
        <li>Respond to lawsuits and legal actions</li>
      </ul>

      <h2>Your Rights (Detailed)</h2>
      <p>
        When it comes to your health information, you have certain rights. This section explains your rights and some of our responsibilities to help you.
      </p>
      <h3>Get an electronic or paper copy of your medical record</h3>
      <ul>
        <li>You can ask to see or get a copy of your medical record and other health information we have about you. Ask us how to do this.</li>
        <li>We will provide a copy or a summary of your health information, usually within 30 days of your request. We may charge a reasonable, cost-based fee.</li>
      </ul>

      <h3>Ask us to correct your medical record</h3>
      <ul>
        <li>You can ask us to correct health information about you that you think is incorrect or incomplete. Ask us how to do this.</li>
        <li>We may say “no” to your request, but we’ll tell you why in writing within 60 days.</li>
      </ul>

      <h3>Request confidential communications</h3>
      <ul>
        <li>You can ask us to contact you in a specific way (for example, home or office phone) or to send mail to a different address.</li>
        <li>We will say “yes” to all reasonable requests.</li>
      </ul>

      <h3>Ask us to limit what we use or share</h3>
      <ul>
        <li>You can ask us not to use or share certain health information for treatment, payment, or our operations. We are not required to agree to your request, and we may say “no” if it would affect your care.</li>
      </ul>

      <h3>Get a list of those with whom we’ve shared information</h3>
      <ul>
        <li>You can ask for a list (accounting) of the times we’ve shared your health information for six years prior to the date you ask, who we shared it with, and why.</li>
        <li>We will include all the disclosures except for those about treatment, payment, and health care operations, and certain other disclosures (such as any you asked us to make).</li>
        <li>We’ll provide one accounting a year for free but will charge a reasonable, cost-based fee if you ask for another one within 12 months.</li>
      </ul>

      <h2>For Further Information</h2>
      <p>
        To exercise your rights, for questions, or further assistance regarding this Notice, you may contact the Privacy Officer by email at <a href="mailto:legal@sweetspotfarms.com">legal@sweetspotfarms.com</a> or by writing to:
      </p>
      <ul>
        <li>100 Pioneer Ave, Warwick, RI 02888</li>
      </ul>
    </div>
    </div>
  );
};

export default HIPAA;
