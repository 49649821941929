import React, { useState } from 'react';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import CustomInfoWindow from './CustomInfoWindow.tsx';

// Styles for the map container
const mapContainerStyle = {
  flex: 1, // Allows map to expand inside flex container
  height: '600px' // Set to a specific height (increased from 500px)
};

// Center of the map - adjusted slightly to better center the pins
const center = {
  lat: 41.6,
  lng: -73.5,
};

const mapId = '4f098898d4a7d2d9';

const options = {
  mapId: mapId, 
  disableDefaultUI: true, 
};

export const stores = [
  {
    id: 1,
    name: 'Stamford',
    address: '111 High Ridge Rd, Stamford, CT 06905',
    phone: '(860) 321-1291',
    position: { lat: 41.05343, lng: -73.53873 },
  },
  {
    id: 2,
    name: 'West Hartford',
    address: '2 Park Rd, West Hartford, CT 06119',
    phone: '(860) 838-8330',
    position: { lat: 41.76204, lng: -72.74204 },
  },
  {
    id: 3,
    name: 'Olney',
    address: '18070 Georgia Ave, Olney MD 20832',
    phone: '(301) 774-0840',
    position: { lat: 39.15288, lng: -77.06756 },
  },
  {
    id: 4,
    name: 'Voorhees',
    address: '903 White Horse Rd, Vorhees, NJ 08043',
    phone: '(856) 882-3481',
    position: { lat: 39.84794, lng: -74.98883 },
  },
  {
    id: 5,
    name: 'Maplewood',
    address: '751 Irvington Ave, Maplewood, NJ 07040',
    phone: '(973) 327-2027',
    position: { lat: 39.84794, lng: -74.244202 },
  },
  {
    id: 6,
    name: 'Exeter',
    address: '560 S. County Trail, Exeter, RI 02822',
    phone: '(401) 268-5126',
    position: { lat: 40.730972, lng: -71.54891 },
  },
  {
    id: 7,
    name: 'Essex Junction',
    address: '139 Pearl St, Essex Junction, VT 05452',
    phone: '(802) 871-5895',
    position: { lat: 44.49715, lng: -73.12514 },
  },
];

export default function StoresMap() {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyB-quY1J4YQRClGu0AXpUIvZOu23xB2_fA',
  });
  const [selectedMarker, setSelectedMarker] = useState<typeof stores[0] | null>(null);

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <GoogleMap 
      mapContainerStyle={mapContainerStyle} 
      zoom={6.2} // Increased zoom from 6 to 7 to focus more on the pins
      center={center} 
      options={options}
    >
      {stores.map((store) => {
        const icon = {
          url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(
            `<svg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'>
              <circle cx='20' cy='20' r='10' fill=' #00443B' />
              <text x='20' y='24' font-size='10' text-anchor='middle' fill='white' font-family='sans-serif'>${store.id}</text>
            </svg>`
          )}`,
          scaledSize: new window.google.maps.Size(60, 60),
        };

        return (
          <Marker
            key={store.id}
            position={store.position}
            title={store.name}
            icon={icon}
            onClick={() => setSelectedMarker(store)}
          />
        );
      })}

      {selectedMarker && (
        <CustomInfoWindow
          position={selectedMarker.position}
          onCloseClick={() => setSelectedMarker(null)}
        >
          <div>
            <p style={{ fontSize: '14px', letterSpacing: '1px' }}>{selectedMarker.name.toUpperCase()}</p>
            <p>{selectedMarker.address}</p>
            <p>{selectedMarker.phone}</p>
          </div>
        </CustomInfoWindow>
      )}
    </GoogleMap>
  );
}
