import React from 'react';
// import { useNavigate } from 'react-router-dom';
import LogoIconPaleGreen from '../../logo-icon-pale-green.png';
import RoundedButton from '../../RoundedButton.tsx';

export default function FranchiseFooter(props: any) {
  // const navigate = useNavigate();

  // const handleInfoButton = () => {
  //   navigate('/franchising/info-request');
  // };

  return (
    <footer className="FranchiseFooter">
      <div className="contentWrapper">
        <div className="content">
          <div className="blurb">
            <img src={LogoIconPaleGreen} alt="Sweetspot icon" />
            <p>
              Interested in becoming a partner? Let's chat! 3 ways to get in touch with us...
            </p>
            <div className="buttonContainer">
              <a 
                href="https://sweetspot-web-legacy-ee0da6c33a23.herokuapp.com/franchising/info-request"
                target="_blank"
                rel="noopener noreferrer"
                className="button"
              >
                Request Info
              </a>
            </div>
          </div>
          <div className="itemWrapper">
            <div className="item">
              <p>
                Fill out the form and we will get in touch.
              </p>
              <div className="infoButtonContainer">
                <RoundedButton href="/franchising/info-request">
                  REQUEST INFORMATION
                </RoundedButton>
              </div>
            </div>
            <div className="item">
              <p>
                Call and leave a message with our Franchise Director:
              </p>
              <span className="phone">
                <a href="tel:+12032000697">
                  203.200.0697
                </a>
              </span>
            </div>
            <div className="item">
              <p>
                Send us an email:
              </p>  
              <span className="email">
                <a href="mailto: franchise@sweetspotfarms.com">
                  franchise@sweetspotfarms.com
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="subText">
        <p>
          Sweetspot franchises are not currently being offered for locations in California, Florida, Hawaii, Illinois, Indiana, Kentucky, Michigan, Minnesota, Nebraska, New York, North Dakota, South Dakota, Texas, Utah, Virginia, Wisconsin, and Washington. 
        </p>
      </div>
    </footer> 
  );
}
