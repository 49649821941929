import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Footer from './Footer.tsx';

export default function MenuPage() {
  const { menuId } = useParams();
  const menu = `menu${menuId}`;

  useEffect(() => {
    // Inject IHeartJane script into the DOM
    const id = menu.replace('menu', '');
    const storeMenu = document.getElementById(menu);
    const storeScript = document.createElement("script");
    storeScript.src = `https://api.iheartjane.com/v1/stores/${id}/embed.js`;
    storeScript.id = "jane-frame-script"; // Required per documentation
    storeScript.async = true;
    storeMenu?.appendChild(storeScript);

    return () => {
      storeMenu?.removeChild(storeScript);
    };
  }, [menu]);

  return (
    <div style={{ 
      fontFamily: 'Arial, sans-serif',
      minHeight: '100vh',
      width: '100%',
      overflow: 'auto',
      display: 'flex',
      flexDirection: 'column'
    }}>
      <header style={{ 
        backgroundColor: '#f8f8f8', 
        padding: '10px 0', 
        borderBottom: '1px solid #ddd',
        height: '72px',
        flexShrink: 0
      }}>
      </header>
      <main style={{
        position: 'relative',
        flex: '1 0 auto',
        display: 'flex',
        flexDirection: 'column'
      }}>
        <div style={{ 
          flex: 1,
          position: 'relative'
        }}>
          <div id={menu} style={{ 
            width: '100%',
            minHeight: '600px'
          }} />
        </div>
      </main>
      <Footer />
    </div>
  );
}