import React from 'react';
import './LegalDisclaimers.css';

const LegalDisclaimers = () => {
  return (
    <div className="header-offset">
    <div className="legal-disclaimers-container">
      <h1>Legal Disclaimers</h1>
      <p><strong>Last Updated:</strong> May 11, 2022</p>

      <h2>Purpose of Policy</h2>
      <p>
        Sweetspot Brands LLC, a Rhode Island limited liability company d/b/a “Sweetspot” (together with its affiliated companies, “Sweetspot”) operates the Internet site located at 
        <a href="https://www.sweetspotfarms.com" target="_blank" rel="noopener noreferrer"> www.sweetspotfarms.com</a> (the “Site”) in support of its ownership and operation of licensed marijuana facilities in various States in which such uses are permitted by local and State law. All marijuana products advertised on the Site are offered by the Sweetspot facility solely in locations where and under conditions in which such products may be legally sold.
      </p>

      <h2>Marijuana and Marijuana Product Disclaimer</h2>
      <p>
        Marijuana is a Schedule 1 Controlled Substance under the Controlled Substance Act (21 U.S.C. 802) (“CSA”) and the cultivation, distribution, and possession of marijuana is a crime under federal law. Keep all marijuana and marijuana products out of reach of children and animals. Intoxicating effects of marijuana and marijuana products may be delayed up to two (2) hours. Use of marijuana while pregnant or breastfeeding may be harmful. Consumption of marijuana and marijuana products impairs your ability to drive and operate machinery, please use extreme caution.
      </p>

      <h2>Assumption of Liability</h2>
      <p>
        YOU ASSUME THE RISK OF ANY-AND-ALL DAMAGE OR LOSS INCURRED AS A RESULT OF YOUR CONSUMPTION OF MARIJUANA OR MARIJUANA PRODUCTS OBTAINED FROM ANY SWEETSPOT DISPENSARY, STORE, DELIVERY PERSON, OR OTHER SWEETSPOT SERVICE. ALTHOUGH THE SITE, SWEETSPOT PROMOTIONAL CONTENT, OR SWEETSPOT REPRESENTATIVES MAY MAKE STATEMENTS REGARDING THE MARIJUANA AND MARIJUANA PRODUCTS AVAILABLE FROM SWEETSPOT, INCLUDING BUT NOT LIMITED TO, POTENCY, PHARMACOLOGICALLY ACTIVE INGREDIENTS, SOURCE OF CULTIVATION, RECOMMENDED USES, POSSIBLE BUT UNPROVEN BENEFITS, AND ALL OTHER DESCRIPTIONS OR INFORMATION (COLLECTIVELY “MARIJUANA INFORMATION”), SUCH STATEMENTS SHOULD IN NO WAY BE CONSTRUED AS MEDICAL ADVICE.
      </p>
      <p>
        THESE STATEMENTS HAVE NOT BEEN EVALUATED BY THE FOOD AND DRUG ADMINISTRATION. SWEETSPOT PRODUCTS ARE NOT INTENDED TO DIAGNOSE, TREAT, CURE OR PREVENT ANY DISEASE OR AILMENT. SWEETSPOT DOES NOT MAKE ANY HEALTH CLAIMS ABOUT ITS PRODUCTS SOLD, AND RECOMMENDS CONSULTING WITH A QUALIFIED MEDICAL DOCTOR OR PHYSICIAN PRIOR TO CONSUMING SWEETSPOT-PROVIDED PRODUCTS OR PREPARING A TREATMENT PLAN. ALL MARIJUANA INFORMATION IS PROVIDED FOR INFORMATIONAL AND EDUCATIONAL PURPOSES ONLY AND IS NOT INTENDED TO BE A SUBSTITUTE FOR MEDICAL ADVICE UNDER ANY CIRCUMSTANCE.
      </p>

      <h2>Acknowledgement</h2>
      <p>
        By accessing the Site and the services offered, you acknowledge that you have read and understand these legal disclaimers together with Sweetspot’s terms of service and privacy policy.
      </p>
    </div>
    </div>
  );
};

export default LegalDisclaimers;
