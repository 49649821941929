import React, { useState, useEffect } from 'react';
import './ThreeColumnSection.css';
import quizImage from './Intro_Quiz_Icon_01.png';
import starterKitImage from './Starter_Kit_Icon_01.png';
import speakToSomeoneImage from './Advisor_Icon_01.png';
import StarterKitImage from "./Starter-kit-01.png"; // Make sure this file is in the same folder

const ThreeColumnSection: React.FC = () => {
    const { openStarterKitModal, StarterKitModal } = useStarterKitModal();

    return (
        <div className="three-column-section">
            
            {/* This wrapper ensures columns + dividers stay on a single row at desktop */}
            <div className="columns-row">
                <div className="column">
                    <div className="column-content">
                        <img src={quizImage} alt="Take the Quiz" className="column-image" />
                        <h3 className="column-title">TAKE THE INTRO QUIZ</h3>
                        <p className="column-text">New to cannabis? <br></br>Answer 6 questions to <br></br>find your sweetspot.</p>
                    </div>
                    <a href="https://sweetspot-web-legacy-ee0da6c33a23.herokuapp.com/quiz" className="column-subtext-link">
                        <div className="column-button-circle"></div>
                        <span className="column-subtext">Take the quiz</span>
                    </a>
                </div>

                <div className="divider"></div>

                <div className="column">
                    <div className="column-content">
                        <img src={starterKitImage} alt="Starter Kit" className="column-image" />
                        <h3 className="column-title">GET A STARTER KIT</h3>
                        <p className="column-text">Try a variety of strains <br/> and products at an<br/> affordable price.</p>
                    </div>
                    <a href="#" className="column-subtext-link" onClick={(e) => {
                        e.preventDefault();
                        openStarterKitModal();
                    }}>
                        <div className="column-button-circle"></div>
                        <span className="column-subtext">Learn more</span>
                    </a>
                    <div className="mobile-disclaimer">* Only available in Rhode Island</div>
                </div>

                <div className="divider"></div>

                <div className="column">
                    <div className="column-content">
                        <img src={speakToSomeoneImage} alt="Speak to Someone" className="column-image" />
                        <h3 className="column-title">CHAT WITH AN ADVISOR</h3>
                        <p className="column-text">
                            Work with a Sweetspot <br/> cannabis advisor 
                            during your <br/> first shopping experience.
                        </p>
                    </div>
                    <a href="/store  " className="column-subtext-link">
                        <div className="column-button-circle"></div>
                        <span className="column-subtext">Find a store</span>
                    </a>
                </div>
            </div> 
            <div className="desktop-disclaimer">* Only available in Rhode Island</div>
            <StarterKitModal />
        </div>
    );
};

export default ThreeColumnSection;

/**
 * Custom hook to handle responsive styles
 */
function useResponsiveStyles() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return {
    modal: {
      backgroundColor: "#00443B",
      padding: isMobile ? "20px 20px 30px" : "40px 50px 50px",
      borderRadius: "8px",
      width: "90%",
      maxWidth: isMobile ? "500px" : "800px",
      textAlign: "center",
      fontFamily: "SimplonMono, monospace",
      fontSize: isMobile ? "20px" : "24px",
    },
    heading: {
      color: "#fff",
      marginTop: isMobile ? "20px" : "40px",
      fontWeight: 300,
      marginBottom: isMobile ? "40px" : "90px",
      fontSize: isMobile ? "18px" : "24px",
      lineHeight: isMobile ? "1.4" : "inherit"
    },
    text: {
      color: "#fff",
      marginTop: isMobile ? "15px" : "20px",
      marginBottom: isMobile ? "20px" : "40px",
      fontSize: isMobile ? "14px" : "18px",
      lineHeight: isMobile ? "1.3" : "inherit"
    },
    reference: {
      color: "#fff",
      fontSize: isMobile ? "14px" : "18px",
      margin: isMobile ? "0 0 5px 0" : "0 0 10px 0"
    },
    closeButton: {
      position: "relative",
      width: isMobile ? "200px" : "280px",
      height: isMobile ? "40px" : "50px",
      background: "transparent",
      border: "none",
      color: "#fff",
      fontFamily: "Work Sans, sans-serif",
      fontSize: isMobile ? "14px" : "16px",
      textTransform: "uppercase",
      cursor: "pointer",
      marginTop: isMobile ? "10px" : "20px"
    }
  };
}

/**
 * Custom hook that provides:
 * 1) openStarterKitModal() to open the modal
 * 2) StarterKitModal component to display the modal
 */
function useStarterKitModal() {
  const [isOpen, setIsOpen] = useState(false);

  const openStarterKitModal = () => {
    setIsOpen(true);
  };

  const closeStarterKitModal = () => {
    setIsOpen(false);
  };

  // The actual modal component
  const StarterKitModal = () => {
    const responsiveStyles = useResponsiveStyles();
    if (!isOpen) return null;

    return (
      <div style={styles.backdrop}>
        <div style={responsiveStyles.modal}>
          <img src={StarterKitImage} alt="Starter Kit" style={styles.image} />
          <h2 style={responsiveStyles.heading}>
            Pick any 3 Sweetspot products <br/>and get 20% OFF + a free<br/>
            smell-proof carry case.
          </h2>
          <div style={styles.divider}></div>
          <p style={responsiveStyles.text}>
            This offer valid for one-time use only. To redeem show this deal<br/>
            during check out. Currently only available in Rhode Island.
          </p>
          
          <p style={responsiveStyles.reference}>REF: STARTER KIT</p>
          <button style={responsiveStyles.closeButton} onClick={closeStarterKitModal}>
            <div style={styles.buttonCircle}></div>
            <span style={styles.buttonText}>Close</span>
          </button>
        </div>
      </div>
    );
  };

  return { openStarterKitModal, StarterKitModal };
}

const styles = {
  backdrop: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 9999
  },
  modal: {
    backgroundColor: "#00443B",
    padding: "40px 50px 50px",  // Increased padding top/sides/bottom
    borderRadius: "8px",
    width: "90%",
    maxWidth: "800px",
    textAlign: "center",
    fontFamily: "SimplonMono, monospace",
    fontSize: "24px",
  },
  image: {
    maxWidth: "100%",
    height: "auto"
  },
  heading: {
    color: "#fff",
    marginTop: "40px",
    fontWeight: 300,
    marginBottom: "90px"  // Reduced space between heading and divider
  },
  text: {
    color: "#fff",
    marginTop: "20px",    // Reduced space after divider
    marginBottom: "40px",
    fontSize: "18px"
  },
  divider: {
    width: "100%",
    height: "1px",
    backgroundColor: "#fff",
    margin: "0"           // Reset margin to adjust positioning
  },
  reference: {
    color: "#fff",
    fontSize: "18px",
    margin: "0px 0 10px 0"
  },
  closeButton: {
    position: "relative",
    width: "280px",
    height: "50px",
    background: "transparent",
    border: "none",
    color: "#fff",
    fontFamily: "Work Sans, sans-serif",
    fontSize: "16px",
    textTransform: "uppercase",
    cursor: "pointer",
    marginTop: "20px"
  },
  buttonCircle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "85%",
    height: "120%",
    border: "1px solid #fff",
    borderRadius: "30px",
    transition: "background-color 0.5s ease",
    zIndex: 0
  },
  buttonText: {
    position: "relative",
    zIndex: 1,
    fontWeight: 200
  }
};
