import React, { useState, useEffect } from 'react';
import './Products.css';
import ProductsBackground from './ProductsBackground.tsx';

import gummiesImage from './Product_Gummies_01.png';
import flowerImage from './Product_Flower_01.png';
import prerollsImage from './Product_Pre-Rolls_01.png';
import mistImage from './Product_Mist_01.png';
import vapeImage from './Product_VapeOil_01.png';
import chevronLeft from './Arrow-Right-01.png';
import chevronRight from './Arrow-Left-01.png';

const Products: React.FC = () => {
    const products = [
        { title: 'PRE-ROLLS', image: prerollsImage, alt: 'PRE-ROLLS' },
        { title: 'VAPE OIL', image: vapeImage, alt: 'VAPE OIL' },
        { title: 'FLOWERS', image: flowerImage, alt: 'FLOWERS' },
        { title: 'MIST', image: mistImage, alt: 'MIST' },
        { title: 'GUMMIES', image: gummiesImage, alt: 'GUMMIES' }
    ];
    const extendedProducts = [...products, ...products];
    const [currentIndex, setCurrentIndex] = useState(0);
    const [itemsToShow, setItemsToShow] = useState(1);
    const [slideDirection, setSlideDirection] = useState<'left' | 'right' | ''>('');

    // Reset index when itemsToShow changes to avoid a partial slide
    useEffect(() => {
        setCurrentIndex(0);
    }, [itemsToShow]);

    const handlePrev = () => {
        setSlideDirection('right');
        setCurrentIndex((prev) => prev - 1);
    };

    const handleNext = () => {
        setSlideDirection('left');
        setCurrentIndex((prev) => prev + 1);
    };

    useEffect(() => {
        if (currentIndex >= products.length) {
            setCurrentIndex(currentIndex - products.length);
        } else if (currentIndex < 0) {
            setCurrentIndex(currentIndex + products.length);
        }
    }, [currentIndex, products.length]);

    useEffect(() => {
        function handleResize() {
            // Always show one item, regardless of screen size
            setItemsToShow(1);
        }
        
        handleResize();
        
        // Debounce resize handler to prevent rapid re-renders
        let resizeTimer: NodeJS.Timeout;
        const debouncedResize = () => {
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(handleResize, 250);
        };

        window.addEventListener('resize', debouncedResize);
        return () => {
            window.removeEventListener('resize', debouncedResize);
            clearTimeout(resizeTimer);
        };
    }, []);

    const prevProductTitle = products[(currentIndex - 1 + products.length) % products.length].title;
    const nextProductTitle = products[(currentIndex + 1) % products.length].title;

    return (
        <div className="products-wrapper">
            <div className="product-titles-row">
                {products.map((product, idx) => (
                    <div 
                        key={idx} 
                        className={`desktop-product-title ${idx === currentIndex ? 'active' : ''}`}
                        onClick={() => setCurrentIndex(idx)}
                    >
                        {product.title}
                    </div>
                ))}
            </div>
            <div className="slider-controls">
                <button
                    className="slider-chevron left"
                    onClick={handlePrev}
                >
                    <img src={chevronLeft} alt="Previous" />
                </button>
                <div className="slider-viewport">
                    <div
                        className={`preview-title left ${slideDirection ? `exit-${slideDirection}` : ''}`}
                        onClick={handlePrev}
                        style={{ cursor: 'pointer' }}
                    >
                        {prevProductTitle}
                    </div>
                    <div
                        className="slider-track"
                        style={{ transform: `translateX(-${100 * currentIndex}%)` }}
                        onTransitionEnd={() => setSlideDirection('')}
                    >
                        {extendedProducts.map((product, idx) => (
                            <div key={idx} className={`slider-item ${slideDirection ? `enter-${slideDirection}` : ''}`}>
                                <h3 className="product-title">                                    {product.title}                                </h3>
                                <img
                                    src={product.image}
                                    alt={product.alt}
                                    className="product-image"
                                />
                            </div>
                        ))}
                    </div>
                    <div
                        className={`preview-title right ${slideDirection ? `exit-${slideDirection}` : ''}`}
                        onClick={handleNext}
                        style={{ cursor: 'pointer' }}
                    >
                        {nextProductTitle}
                    </div>
                </div>
                <button className="slider-chevron right" onClick={handleNext}>
                    <img src={chevronRight} alt="Next" />
                </button>
            </div>
            <div className="title-section">
                <h2 className="products-title">OUR PRODUCTS</h2>
                <div className="title-divider"></div>
            </div>
            <div className="products-footer-text">
                <div className="footer-text-desktop">
                    Our products use 100% pesticide-free <br/>Sweetspot flower, with eco-friendly<br/>packaging made from compostable and<br/> post-consumer recycled materials.
                </div>
                <div className="footer-text-mobile">
                    Our products use 100% <br/> pesticide-free Sweetspot <br/>flower, with eco-friendly <br/>packaging made from <br/>compostable and <br/>post-consumer recycled <br/>materials.
                </div>
            </div>
        </div>
    );
};

export default Products;
