import React from 'react';
import './RefundPolicy.css';

const RefundPolicy = () => {
  return (
    <div className="header-offset">
    <div className="refund-policy-container">
      <h1>Refund and Exchange Policy</h1>

      <h2>Cannabis Product Exchange Policy</h2>
      <p>
        Sweetspot will exchange damaged or defective cannabis products or cannabis-related accessories
        up to seven (7) days after purchase. To be eligible for an exchange, any damaged or defective
        cannabis products or cannabis-related accessories must be accompanied by the original
        receipt. All non-damaged cannabis product or cannabis-related accessories sales are
        final. Sweetspot will only accept exchanges at the Sweetspot store from which the damaged or
        defective products were originally purchased. All exchanges shall be at the discretion of
        Sweetspot. Refunds are not permitted.
      </p>

      <h2>Sweetspot Merchandise Exchange Policy</h2>
      <p>
        Sweetspot will accept unworn merchandise for exchange up to fourteen (14) days after
        purchase. To be eligible for an exchange, Merchandise must be in suitable condition for resale
        with original tags attached and must be accompanied by an original receipt. Sale items are final
        and may not be exchanged. Sweetspot will only accept exchanges of unworn merchandise at the
        Sweetspot store from which the merchandise was originally purchased. All exchanges shall be at
        the discretion of Sweetspot. Refunds are not permitted.
      </p>
    </div>
    </div>
  );
};

export default RefundPolicy;
