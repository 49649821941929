import React from 'react';

const franchiseSteps = [
  'Call with Franchise Director.',
  'Complete franchise candidate application.',
  'Review Franchise Disclosure Document.',
  'Begin mutual discovery process.',
  'Attend Discovery Day.',
  'Sweetspot approves franchise candidate.',
  'Complete background check and credit check.',
  'Execute franchise agreement.',
];

export default function NextSteps() {
  return (
    <section className="NextSteps">
      <div className="contentWrapper">
        <div className="content">
          <div className="stepsWrapper">
            { franchiseSteps.map((step, index) => {
              const stepNum = `${index + 1 < 10 ? '0' : ''}${index + 1}`;
              return (
                <div className="step" key={index}>
                  <span className="num">
                    {stepNum}
                  </span>
                  <span className="text">
                    {step}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
}
