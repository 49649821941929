import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';
import instagramLogo from './instagram-logo-01.png';

const footerLinks = {
  legal: [
    { title: 'Privacy Policy', path: '/privacy-policy', isRouter: true },
    { title: 'Legal Disclaimers', path: '/legal-disclaimers' },
    { title: 'Terms of Use', path: '/terms-of-use' },
    { title: 'HIPAA Statement', path: '/hipaa' },
    { title: 'Cannabis Safety Information', path: '/CtCannabisInfo' }
  ],
  main: [
    { title: 'Store', path: '/store' },
    { title: 'Franchise', path: '/franchising' },
    { title: 'Refund Policy', path: '/refund-policy' },
    { title: 'Accessibility Statement', path: '/accessibility' }
  ],
  social: {
    instagram: 'https://www.instagram.com/sweetspotfarms',
    email: 'contact@sweetspotfarms.com'
  }
};

const FooterLinkList = ({ links }: { links: Array<{ title: string, path: string, isRouter?: boolean }> }) => (
  <ul className="footer-list">
    {links.map(({ title, path, isRouter }) => (
      <li key={path}>
        {isRouter ? (
          <Link to={path} className="footer-link">{title}</Link>
        ) : (
          <a href={path} className="footer-link">{title}</a>
        )}
      </li>
    ))}
  </ul>
);

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section">
          <FooterLinkList links={footerLinks.legal} />
        </div>
        <div className="footer-section">
          <FooterLinkList links={footerLinks.main} />
        </div>
        <div className="footer-section footer-social">
          <div className="footer-divider"></div>
          <div className="footer-text">FOLLOW US:</div>
          <a href={footerLinks.social.instagram} className="footer-icon-link">
            <img 
              src={instagramLogo}
              alt="Instagram" 
              className="footer-social-icon"
            />
          </a>
          <a href={`mailto:${footerLinks.social.email}`} className="footer-email">
            {footerLinks.social.email}
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
