import React from 'react';
import './RoundedButton.css';

interface RoundedButtonProps {
    href: string;
    children: React.ReactNode;
}

const RoundedButton: React.FC<RoundedButtonProps> = ({ href, children }) => {
    return (
        <a href={href} className="rounded-button">
            {children}
        </a>
    );
};

export default RoundedButton;
