import React, { useState, useRef, useEffect } from 'react';
import ChevronDownGreen from '../../chevron-down-green.png';

export const franchiseFAQ = [
  {
    id: 'general',
    question: 'How does franchising work in the cannabis industry?',
    answer: 'Franchising in the cannabis industry is just like franchising in any other industry – fitness, food, etc. Sweetspot Franchising LLC has registered their franchise offering/FDD with various states that have legalized cannabis.',
  },
  {
    id: 'why',
    question: 'Why should I choose working with Sweetspot vs. doing this on my own?',
    answer: 'The cannabis industry is complex and ever changing. Without the proper know-how, opening and operating an independent dispensary can be difficult and oftentimes extremely costly. With years of hands-on experience operating successful businesses across 5 states, Sweetspot has developed a blueprint for achieving success within the cannabis industry. Franchising a Sweetspot will allow you to avoid costly mistakes, gain access to valuable resources, get to market faster, and become part of a well loved and recognized brand with a growing footprint.',
  },
  {
    id: 'source',
    question: 'How do you source the cannabis products for resale?',
    answer: 'Due to federal regulations, cannabis products cannot be shipped across state lines. Cannabis products are sourced from local cultivators within the state that dispensaries operate in. Sweetspot will use existing cultivator relationships to aid in sourcing products and achieving fair wholesale pricing.',
  },
  {
    id: 'process',
    question: 'What does the process of becoming a franchisee look like?',
    answer: 'Candidates begin a mutual discovery process which includes a series of conversations and meetings that ensure we are a good fit for one another. Our Franchise Director is your guide in answering any questions about the business, franchisor, franchisee requirements and FDD. We would schedule a site visit at one of our existing dispensaries for a Discovery Day where you will have the opportunity to see operations in person as well as meet with the operations teams. If qualifications are met based on your business acumen and financial strength, a background check and credit check will be submitted. Upon the receipt of a successful result, a franchise agreement will be executed.',
  },
  {
    id: 'cost',
    question: 'What does it cost to become a franchisee?',
    answer: 'Just like any franchise model, you can anticipate a franchisee fee upon signing of the franchise agreement. In addition to a Product Sourcing Fee – 2% of gross sales (capped at $249,999 per calendar year), you will also be responsible for build-out costs, product supplies, security systems, walk-in vaults, computer systems, and other operating expenses. These fees are detailed in the FDD.',
  },
  {
    id: 'support',
    question: 'What kind of support and ongoing support do you provide?',
    answer: 'Aside from all of the pre-opening support to ensure you successfully open your doors, our franchise operations team will continue to work with you after opening to ensure you are achieving all growth milestones through continued frequent communication, in location visits and other support as required. Being a part of a network or system also allows for on-going collaboration and camaraderie which you don’t experience being one your own.',
  },
]

const FAQChevron = ({ isActive }: { isActive: boolean }) => {
  const iconClassName = `panelIcon${isActive ? ' rotated' : ''}`;
  return (
    <img 
      src={ChevronDownGreen}
      alt="Chevron down green"
      className={iconClassName}
    />
  );
};

const FAQTitle = ({ text }: { text: string }) => (
  <span className="title">{text}</span>
);

const FAQItem = ({ id, question, answer }: { id: string, question: string, answer: string }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [height, setHeight] = useState<string>('0px');
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (contentRef.current) {
      const scrollHeight = contentRef.current.scrollHeight;
      setHeight(isExpanded ? `${scrollHeight}px` : '0px');
    }
  }, [isExpanded]);

  return (
    <div className="faqItem">
      <button 
        className="header"
        onClick={() => setIsExpanded(!isExpanded)}
        aria-expanded={isExpanded}
      >
        <div className="chevronWrapper">
          <FAQChevron isActive={isExpanded} />
        </div>
        <FAQTitle text={question} />
      </button>
      <div 
        className="answer-wrapper"
        style={{ height }}
      >
        <div ref={contentRef} className="answer">
          <p>{answer}</p>
        </div>
      </div>
    </div>
  );
};

export default function FAQ() {
  return (
    <section className="FranchiseFAQ">
      <div className="contentWrapper">
        <div className="content">
          {franchiseFAQ.map((faqItem) => (
            <FAQItem 
              key={faqItem.id}
              id={faqItem.id}
              question={faqItem.question}
              answer={faqItem.answer}
            />
          ))}
        </div>
      </div>
    </section>
  );
}

