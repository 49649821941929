import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Stores.css';
import StoresMap from './StoresMap.tsx';
import ChevronDownGreen from './chevron-down-green.png';
import DispensaryInfo from './DispensaryInfo.tsx';
import { Helmet } from 'react-helmet';

// Update Store interface to include medical/recreational IDs
interface Store {
  id: number;
  name: string;
  address: string;
  phone?: string;  // made optional since NY store doesn't have it
  isMedical?: boolean;
  isRecreational?: boolean;
  medicalId?: string;
  recreationalId?: string;
  monHours: string;
  tuesHours: string;
  wedHours: string;
  thursHours: string;
  friHours: string;
  satHours: string;
  sunHours: string;
}

const states = [
  {
    name: 'CONNECTICUT',
    stores: [
      {
        id: 1,
        name: 'STAMFORD',
        address: '111 High Ridge Rd, Stamford, CT 06905',
        phone: '(860) 321-1291',
        isMedical: true,
        isRecreational: true,
        medicalId: '6158',
        recreationalId: '6159',
        monHours: "Mon: 10am — 7pm",
        tuesHours: "Tues: 10am — 7pm",
        wedHours: "Weds: 10am — 7pm",
        thursHours: "Thurs: 10am — 7pm",
        friHours: "Fri: 10am — 7pm",
        satHours: "Sat: 10am — 7pm",
        sunHours: "Sun: 11am — 5pm",
      },
      {
        id: 2,
        name: 'WEST HARTFORD',
        address: '2 Park Rd, West Hartford, CT 06119',
        phone: '(860) 838-8330',
        isMedical: true,
        isRecreational: true,
        medicalId: '5796',
        recreationalId: '5797',
        monHours: "Mon: 10am — 7pm",
        tuesHours: "Tues: 10am — 7pm",
        wedHours: "Weds: 10am — 7pm",
        thursHours: "Thurs: 10am — 7pm",
        friHours: "Fri: 10am — 7pm",
        satHours: "Sat: 10am — 7pm",
        sunHours: "Sun: 10am — 7pm",
      },
    ],
  },
  {
    name: 'MARYLAND',
    stores: [
      {
        id: 3,
        name: 'OLNEY',
        address: '18070 Georgia Ave, Olney MD 20832',
        phone: '(301) 774-0840',
        isMedical: true,
        isRecreational: true,
        medicalId: '857',
        recreationalId: '5440',
        monHours: "Mon: 10am — 8pm",
        tuesHours: "Tues: 10am — 8pm",
        wedHours: "Weds: 10am — 8pm",
        thursHours: "Thurs: 10am — 8pm",
        friHours: "Fri: 10am — 8pm",
        satHours: "Sat: 10am — 8pm",
        sunHours: "Sun: 10am — 8pm",
      }
    ],
  },
  
  {
    name: 'NEW JERSEY',
    stores: [
      {
        id: 4,
        name: 'VOORHEES',
        address: '903 White Horse Rd, Vorhees, NJ 08043',
        phone: '(856) 882-3481',
        isMedical: true,
        isRecreational: true,
        medicalId: '4855',
        recreationalId: '5377',
        monHours: "Mon: 10am — 8pm",
        tuesHours: "Tues: 10am — 8pm",
        wedHours: "Weds: 10am — 8pm",
        thursHours: "Thurs: 10am — 8pm",
        friHours: "Fri: 10am — 8pm",
        satHours: "Sat: 10am — 8pm",
        sunHours: "Sun: 10am — 8pm",
      },
      {
        id: 5,
        name: 'MAPLEWOOD',
        address: '751 Irvington Ave, Maplewood, NJ 07040',
        phone: '(973) 327-2027',
        isMedical: false,
        isRecreational: true,
        recreationalId: '6246',
        monHours: "Mon: 11am — 8pm",
        tuesHours: "Tues: 11am — 8pm",
        wedHours: "Weds: 11am — 8pm",
        thursHours: "Thurs: 11am — 8pm",
        friHours: "Fri: 11am — 8pm",
        satHours: "Sat: 11am — 8pm",
        sunHours: "Sun: 11am — 8pm",
      }
    ],
  },
  {
    name: 'RHODE ISLAND',
    stores: [
      {
        id: 6,
        name: 'EXETER',
        address: '560 S. County Trail, Exeter, RI 02822',
        phone: '(401) 268-5126',
        isMedical: true,
        isRecreational: true,
        medicalId: '4545',
        recreationalId: '4996',
        monHours: "Mon: 9am — 8pm",
        tuesHours: "Tues: 9am — 8pm",
        wedHours: "Weds: 9am — 8pm",
        thursHours: "Thurs: 9am — 8pm",
        friHours: "Fri: 9am — 8pm",
        satHours: "Sat: 9am — 8pm",
        sunHours: "Sun: 9am — 8pm",
      },
    ],
  },
  {
    name: 'VERMONT',
    stores: [
      {
        id: 7,
        name: 'ESSEX JUNCTION',
        address: '139 Pearl St, Essex Junction, VT 05452',
        phone: '(802) 871-5895',
        isMedical: false,
        isRecreational: true,
        recreationalId: '6145',
        monHours: "Mon: 10am — 7pm",
        tuesHours: "Tues: 10am — 7pm",
        wedHours: "Weds: 10am — 7pm",
        thursHours: "Thurs: 10am — 7pm",
        friHours: "Fri: 10am — 7pm",
        satHours: "Sat: 10am — 7pm",
        sunHours: "Sun: 10am — 7pm",
      },
    ],
  },
];

export default function Stores({ onMenuSelect }: { onMenuSelect: (menuId: string) => void }) {
  const [expandedStates, setExpandedStates] = useState<string[]>([]);
  const [selectedStore, setSelectedStore] = useState<Store | null>(null);
  const navigate = useNavigate();
  const location = useLocation();

  const scrollToStore = () => {
    const isMobile = window.innerWidth <= 768;
    if (isMobile) {
      setTimeout(() => {
        const dispensaryContainer = document.querySelector('.dispensary-container');
        if (dispensaryContainer) {
          const headerHeight = 96;
          const containerTop = dispensaryContainer.getBoundingClientRect().top;
          const scrollPosition = window.pageYOffset + containerTop - headerHeight;
          
          window.scrollTo({
            top: scrollPosition,
            behavior: 'smooth'
          });
        }
      }, 0);
    }
  };

  useEffect(() => {
    const path = location.pathname.toLowerCase().split('/').filter(Boolean);
    
    if (path.length === 1 && path[0] === 'store') {
      setExpandedStates([]);
      setSelectedStore(null);
      return;
    }

    if (path.length > 1 && path[0] === 'store') {
      const stateParam = decodeURIComponent(path[1].replace(/-/g, ' '));
      const storeParam = path[2] ? decodeURIComponent(path[2].replace(/-/g, ' ')) : null;

      const matchingState = states.find(
        state => state.name.toLowerCase() === stateParam
      );

      if (matchingState) {
        setExpandedStates([matchingState.name]);

        if (storeParam) {
          const matchingStore = matchingState.stores.find(
            store => store.name.toLowerCase() === storeParam
          );
          if (matchingStore) {
            setSelectedStore(matchingStore);
            scrollToStore(); // Add scroll behavior for URL-based selection
          }
        } else {
          setSelectedStore(null);
        }
      }
    }
  }, [location.pathname]);

  const toggleState = (state: string) => {
    const encodedStatePath = state.toLowerCase().replace(/ /g, '-');
    
    // If the state is already expanded, close it and go back to base route
    if (expandedStates.includes(state)) {
      setExpandedStates([]);
      setSelectedStore(null);
      navigate('/store');
    } else {
      // Open the state and navigate to its route
      setExpandedStates([state]);
      setSelectedStore(null);
      navigate(`/store/${encodedStatePath}`);
    }
  };

  const handleStoreClick = (store: Store) => {
    setSelectedStore(store);
    
    const state = states.find(
      state => state.stores.some(s => s.id === store.id)
    );
    
    if (state) {
      const encodedStateName = state.name.toLowerCase().replace(/ /g, '-');
      const encodedStoreName = store.name.toLowerCase().replace(/ /g, '-');
      navigate(`/store/${encodedStateName}/${encodedStoreName}`);
    }

    scrollToStore(); // Use the same scroll function for click-based selection
  };

  return (
    <div className="stores-container">
       <Helmet>
        <title>Sweetspot Cannabis Dispensary | Find a Store</title>
        <meta charSet="utf-8" />
        <meta name="description" content="Find your nearest Sweetspot Cannabis dispensary location. We have stores across Connecticut, Maryland, New Jersey, Rhode Island, and Vermont." />
        <meta name="keywords" content="cannabis dispensary, marijuana store, medical cannabis, recreational marijuana, Sweetspot Cannabis locations" />
        <meta property="og:title" content="Sweetspot Cannabis Dispensary | Find a Store" />
        <meta property="og:description" content="Find your nearest Sweetspot Cannabis dispensary location across multiple states." />
        <meta property="og:type" content="website" />
        <link rel="canonical" href="https://sweetspotfarms.com/store" />
      </Helmet>
      <div className="stores-list">
      <div className="stores-list-scroll">
        <div className="list-padding"></div>
        {states.map((state) => (
          <div key={state.name} className="state-section">
            <div
              className="state-header"
              onClick={() => toggleState(state.name)}
            >
              <div className="toggle-icon">
                <img
                  src={ChevronDownGreen}
                  alt="Toggle Icon"
                  style={{ backgroundImage: ChevronDownGreen, transform: expandedStates.includes(state.name) ? 'scale(-0.25)' : 'scale(0.25)' }}
                />
              </div>
              <span>{state.name}</span>
            </div>
            {expandedStates.includes(state.name) && (
              <div className="state-stores">
                {state.stores.length > 0 ? (
                  state.stores.map((store) => (
                    <div 
                      key={store.id} 
                      className="store-item"
                      onClick={(e) => {
                        e.stopPropagation();  // Prevent event bubbling
                        handleStoreClick(store);
                      }}
                      onTouchStart={(e) => {
                        e.stopPropagation();  // Prevent event bubbling
                        handleStoreClick(store);
                      }}
                    >
                      <div className="store-number">{store.id}</div>
                      <div className="store-details">
                        <div className="store-title">{store.name}</div>
                        <div className="store-address">{store.address}</div>
                        <div className="store-phone">{store.phone}</div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="no-stores">No stores available</div>
                )}
              </div>
            )}
          </div>
        ))}
        </div>
        <div style={{background: 'transparent', marginBottom: '0' }}></div>
      </div>
      
      {selectedStore ? (
        <DispensaryInfo store={selectedStore} onMenuClick={(menuId: string) => { onMenuSelect(menuId); return ''; }}/>
      ) : (
        <div className="stores-map" style={{ marginBottom: '0', paddingBottom: '0' }}>
          <StoresMap />
        </div>
      )}
    </div>
  );
}
