import React from 'react';

// Import React Router
// https://reactrouter.com/
import {
  Routes,
  Route,
  Navigate
} from "react-router-dom";

// Import components
import FranchiseInfoRequest from './InfoRequest.tsx';
import FranchiseBanner from './sections/Banner.tsx';
import Blurb from '../Blurb.tsx';
import Industry from './sections/Industry.tsx';
import OurStory from './sections/OurStory.tsx';
import Investment from './sections/Investment.tsx';
import NextSteps from './sections/NextSteps.tsx';
import FranchiseFAQ from './sections/FAQ.tsx';
import FranchiseFooter from './sections/Footer.tsx';
import { Helmet } from 'react-helmet';

function Franchise() {
  return (
    <Routes>
      <Route path="info-request" element={<FranchiseInfoRequest />} />
      <Route path="/" element={
        <main className="Franchise">
        <Helmet>
          <title>Sweetspot Cannabis Dispensary | Franchising</title>
          <meta charSet="utf-8" />
          <meta name="description" content="Join the fast-growing cannabis industry with a Sweetspot Cannabis dispensary franchise. Become part of our successful dispensary network." />
          <meta name="keywords" content="cannabis franchise, marijuana dispensary franchise, cannabis business opportunity, dispensary investment, Sweetspot franchise" />
          <meta property="og:title" content="Sweetspot Cannabis Dispensary | Franchising" />
          <meta property="og:description" content="Join the fast-growing cannabis industry with a Sweetspot Cannabis dispensary franchise. Discover investment opportunities today." />
          <meta property="og:type" content="website" />
          <link rel="canonical" href="https://sweetspotfarms.com/franchising" />
        </Helmet>
          <FranchiseBanner />
          <Blurb
            larger
            text="We are seeking qualified franchisees interested in capitalizing on one of the fastest growing industries of this generation."
          />
          <SectionHeading theme="light" text="The Industry" scrollRef="industry" />
          <Industry />
          <SectionHeading theme="light" text="Our Story" scrollRef="story" />
          <OurStory />

          <SectionHeading theme="light" text="Investment & Fees" scrollRef="investment" />
          <Investment />
          <SectionHeading theme="light" text="The Next Steps" scrollRef="process" />
          <NextSteps />
          <SectionHeading theme="light" text="F.A.Q." scrollRef="faq" />
          <FranchiseFAQ />
          <FranchiseFooter />
        </main>
      } />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}

const SectionHeading = (props) => {
  const {theme, text, scrollRef} = (props);

  const headingClassName = `SectionHeading${theme === 'light' ? ' light' : ''}`;

  return (
    <div className={headingClassName} id={scrollRef}>
      <div className="contentContainer">
        <div className="content">
        <span className="circle"></span>
          <span className="text">
            {text}
          </span>
        </div>
      </div>
    </div>
  );
};

export { SectionHeading };
export default Franchise;