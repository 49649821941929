import React from "react";
import "./DispensaryInfo.css";
import Store1 from "./Homepage_Store_Stamford.png";
import Store2 from "./Homepage_Store_West Hartford.png";
import Store3 from "./store-3.png";
import Store4 from "./store-4.png";
import Store5 from "./store-5.png";
import Store6 from "./Homepage_Store_Exeter.png";
import Store7 from "./Homepage_Store_Essex Junction.png";

interface StoreProps {
    store: {
        id: number;
        name: string;
        address: string;
        phone?: string;
        isMedical?: boolean;
        isRecreational?: boolean;
        medicalId?: string;
        recreationalId?: string;
        monHours: string;
        tuesHours: string;
        wedHours: string;
        thursHours: string;
        friHours: string;
        satHours: string;
        sunHours: string;
    };
    onMenuClick: (type: string) => string;
}

const getImageForStore = (store: StoreProps['store']) => {
  switch (store.id) {
    case 1:
      return Store1;
    case 2:
      return Store2;
    case 3:
      return Store3;
    case 4:
      return Store4;
    case 5:
      return Store5;
    case 6:
      return Store6;
    case 7:
      return Store7;
    default:
      return Store1;
  }
};

const DispensaryInfo: React.FC<StoreProps> = ({ store, onMenuClick }) => {
    return (
      <div className="dispensary-container">

        <div className="dispensary-content">
          <div className="dispensary-left-column">
            <div className="dispensary-header">
              <div className="dispensary-name">{store.name}</div>
              <p>{store.address}</p>
              <p>{store.phone}</p>
            </div>
            <div className="dispensary-menu-button-container" style={{ display: 'flex', flexDirection: 'column' }}>
              {store.isMedical && (
              <button
                className="dispensary-menu-button medical"
                onClick={() => onMenuClick(`${store.medicalId}`)}
              >
                MEDICAL MENU
              </button>
              )}
              {store.isRecreational && (
              <button
                className="dispensary-menu-button recreational"
                onClick={() => onMenuClick(`${store.recreationalId}`)}
              >
                RECREATIONAL MENU
              </button>
              )}
            </div>
          </div>
          <div className="dispensary-right-column">
            <div className="dispensary-hours">
              <div className="dispensary-hours-header"></div>
              <p>{store.monHours}</p>
              <p>{store.tuesHours}</p>
              <p>{store.wedHours}</p>
              <p>{store.thursHours}</p>
              <p>{store.friHours}</p>
              <p>{store.satHours}</p>
              <p>{store.sunHours}</p>
            </div>
          </div>
        </div>
      </div>
    );
};
  
export default DispensaryInfo;
