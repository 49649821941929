import React from 'react';
import './TwoProductView.css';
import flowerJar from './flower-eco-system-03.png'
import flowerBag from './flower-eco-system-02.png'

const TwoProductView = () => {
    return (
        <div className="two-product-view">
            <h2 className="product-title">SWEETSPOT FLOWER <br></br> ECO-SYSTEM</h2>
            <div className="vertical-divider"></div>
            <div className="products-container">
                <div className="product-group">
                    <img src={flowerBag} alt="Flower Pouch" className="product-image" />
                    <div className="caption-left">
                        <h3 className="caption-title">STEP 1</h3>
                        Our premium flowers are sold 
                        in post-<br/>consumer recycled, eco-friendly paper <br/>pouches, 
                        avoiding the use of plastic.
                    </div>
                </div>
                <div className="product-group">
                    <img src={flowerJar} alt="Stash Jar" className="product-image" />
                    <div className="caption-right">
                        <h3 className="caption-title">STEP 2</h3>
                        Once opened, store your flowers<br/> 
                        in our high 
                        quality stash jars to <br/>preserve freshness. Reuse forever! 
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TwoProductView;
