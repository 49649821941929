import React from 'react';
import './EcoPackaging.css';
import './Sustainability.css'
import ecoPackageImage from './eco-friendly-package-01.png';


const EcoPackaging: React.FC = () => {
    return (
        <div className="eco-packaging">
            <h2 className="eco-packaging-title">ECO-FRIENDLY PACKAGING</h2>
            <div className="sustainability-divider"></div>
            <div className="content-columns">
                    <div className="sustainability-quote">
                        <p className="desktop-quote">
                            We're at war with single-use plastic <br></br> 
                            packaging that is widely found in the <br></br> 
                            cannabis industry. From the materials we <br></br>choose, to how they are shipped, our <br></br>
                            packaging sets the green standard.
                        </p>
                        <p className="mobile-quote">
                            We're at war with single-<br></br>use plastic packaging that <br></br>is widely found in the 
                            <br></br>cannabis industry. From <br></br>the materials we choose, <br></br>to how they are shipped, <br></br>our
                            packaging sets the<br></br> green standard.
                        </p>
                    </div>
                </div>
        </div>
    );
};

export default EcoPackaging;
