import React, { useState } from 'react';
import './AgeGate.css';
import logo from './sweetspot-logo-green.png'; // Make sure to add your logo asset

export default function AgeGate({ onVerified }: { onVerified: () => void }) {
  const [isFading, setIsFading] = useState(false);

  const handleNoButton = () => {
    window.location.href = "https://www.google.com/";
  };

  const handleYesButton = () => {
    setIsFading(true);
    setTimeout(() => {
      localStorage.setItem('ageVerified', 'true');
      onVerified();
    }, 500); // Match this with the CSS transition duration
  };

  return (
    <div className={`age-gate ${isFading ? 'fade-out' : ''}`}>
      <div className="gateWrapper">
        <div className="gateContainer">
          <header>
            <div className="image">
              <img src={logo} alt="Sweetspot Logo" />
            </div>
          </header>
          <h1>Find Your Sweetspot</h1>
          <p>Are you 21 or older?</p>
          <div className="gateButtons">
            <button type="button" className="gateButtonNo" onClick={handleNoButton}>
              No I'm not
            </button>
            <button type="button" className="gateButtonYes" onClick={handleYesButton}>
              Yes I am
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
